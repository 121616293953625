import { Controller } from "@hotwired/stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static values = {
    data: Array
  }

  connect() {
    this.renderChart()
  }

  renderChart() {
    const options = {
      series: [
        {
          name: "Deployments",
          data: this.dataValue.map(item => item.count)
        },
        {
          name: "Running Average",
          data: this.dataValue.map(item => item.running_avg)
        }
      ],
      chart: {
        type: "area",
        height: 250,
        toolbar: { show: false }
      },
      dataLabels: { enabled: false },
      stroke: {
        curve: "smooth",
        width: [2, 2],
        dashArray: [0, 6]
      },
      xaxis: {
        type: "datetime",
        categories: this.dataValue.map(item => item.date),
        labels: { format: "MMM yyyy" }
      },
      yaxis: {
        title: { text: "Number of Deployments" },
        labels: {
          formatter: function(val) { return Math.round(val) }
        }
      },
      colors: ["#262626", "#7F7F7F"],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.3,
          stops: [0, 90, 100]
        }
      },
      tooltip: { x: { format: "dd MMM yyyy" } }
    }

    const chart = new ApexCharts(this.element, options)
    chart.render()
  }
}