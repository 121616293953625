import React from 'react';
import { getSmoothStepPath } from '@xyflow/react';

export default function CrewConnectionLine({ fromX, fromY, toX, toY, fromPosition, toPosition }) {
  const [edgePath] = getSmoothStepPath({
    sourceX: fromX,
    sourceY: fromY,
    sourcePosition: fromPosition,
    targetX: toX,
    targetY: toY,
    targetPosition: toPosition,
  });

  return (
    <g>
      <path
        fill="none"
        stroke="#EB6658"
        strokeWidth={2}
        className="animated-dash"
        d={edgePath}
      />
      <circle
        cx={toX}
        cy={toY}
        fill="#fff"
        r={3}
        stroke="#EB6658"
        strokeWidth={1.5}
      />
    </g>
  );
}
