import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "icon", "versionSelect"]

  connect() {
    // Initialize in collapsed state
    this.expanded = false
    this.updateState()
  }

  toggle() {
    this.expanded = !this.expanded
    this.updateState()
  }

  updateState() {
    if (this.hasContentTarget && this.hasIconTarget) {
      this.contentTarget.classList.toggle("hidden", !this.expanded)
      this.iconTarget.style.transform = this.expanded ? "" : "rotate(-90deg)"
    }
  }

  toggleVersions(event) {
    event.stopPropagation()
    const select = event.currentTarget
    const crewId = select.value
    if (crewId) {
      window.location.href = select.options[select.selectedIndex].dataset.url
    }
  }
}