import React, { memo } from 'react';
import { Handle, Position } from '@xyflow/react';

const CrewTaskNode = memo(({ data, isConnectable }) => {
  const parseName = (name) => {
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const getNodeStyle = () => {
    const baseStyle = {
      border: '1px solid #ccc',
      borderRadius: '6px',
      padding: '8px',
      width: '220px',
      minWidth: '220px',
      backgroundColor: '#fff',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      transition: 'all 0.2s ease',
    };

    if (data.isCompleted) {
      return {
        ...baseStyle,
        border: '2px solid #10B981',
        backgroundColor: '#F0FDF4',
      };
    }

    if (data.isRunning) {
      return {
        ...baseStyle,
        border: '2px solid #3B82F6',
        backgroundColor: '#EFF6FF',
      };
    }

    return baseStyle;
  };

  return (
    <div
      className="react-flow__node-taskNode"
      style={getNodeStyle()}
      onClick={() => data.onClick(data)}
    >
      <Handle
        type="target"
        position={Position.Top}
        id={data.name}
        style={{
          background: '#555',
          width: '8px',
          height: '8px',
          top: '-4px',
        }}
        isConnectable={isConnectable}
      />
      <div className="text-[10px] uppercase tracking-wider text-gray-400 font-medium mb-1">
        Task
      </div>
      <div className="task-header mb-0.5">
        <div className="flex items-center justify-between">
          <div className='font-medium text-xs text-gray-800' title={parseName(data.name)}>
            {truncateText(parseName(data.name), 100)}
          </div>
          {data.async_execution && (
            <span className="async-badge px-1 py-0.5 text-xs font-medium bg-purple-100 text-purple-800 rounded-sm ml-1">
              Async
            </span>
          )}
        </div>
      </div>

      <div className="task-body">
        <div className="mb-1 p-1 bg-gray-50 rounded">
          <div className="text-[10px] text-gray-500">Expected Output</div>
          <div className="text-xs text-gray-700" title={data.expected_output}>
            {truncateText(data.expected_output, 45)}
          </div>
        </div>

        <div className="agent-info flex items-center text-[10px]">
          <span className="text-gray-500">Agent:&nbsp;</span>
          <span className="text-gray-700">{data.agent}</span>
        </div>
      </div>

      <Handle
        type="source"
        position={Position.Bottom}
        id={data.name}
        style={{
          background: '#555',
          width: '8px',
          height: '8px',
          bottom: '-4px',
        }}
        isConnectable={isConnectable}
      />
    </div>
  );
});

export default CrewTaskNode;
