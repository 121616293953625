import React from 'react';
import { BaseEdge, EdgeLabelRenderer, getBezierPath } from '@xyflow/react';

export function CrewAnimatedEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
  isAnimated = false
}) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const shouldAnimate = data && data.isAnimated || false;
  const animationClass = shouldAnimate ? 'animated-edge' : '';

  return (
    <React.Fragment>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={{
          ...style,
          strokeWidth: 2,
          stroke: '#555',
          strokeDasharray: shouldAnimate ? '5' : 'none',
        }}
        className={animationClass}
      />
    </React.Fragment>
  );
}
