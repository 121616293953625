import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'item'];

  filter() {
    const query = this.inputTarget.value.toLowerCase().trim();
    this.itemTargets.forEach(chatItem => {
      const text = chatItem.textContent.toLowerCase();
      const hasMatch = text.includes(query);
      chatItem.style.display = hasMatch ? '' : 'none';
    });
  }
}