import React, { memo } from 'react';
import { Handle, Position } from '@xyflow/react';

const CrewOutputNode = memo(({ data, isConnectable }) => {
  const getNodeStyle = () => {
    if (!data.executionStatus) return {};

    return {
      background: data.executionStatus === 'completed' ? '#10B981' : // green
                 data.executionStatus === 'error' ? '#EF4444' : // red
                 'inherit',
      color: data.executionStatus === 'completed' || data.executionStatus === 'error' ? 'white' : 'inherit'
    };
  };

  return (
    <div
      className="react-flow__node-default gradient cursor-pointer"
      style={getNodeStyle()}
      onClick={() => data.executionStatus === 'completed' && data.onResultClick && data.onResultClick(data.result)}
    >
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      <div>Output</div>
    </div>
  );
});

export default CrewOutputNode;
