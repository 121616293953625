// CSS imports
import "@xyflow/react/dist/style.css";
import "@fortawesome/fontawesome-free/js/all";
import "choices.js/public/assets/styles/choices.min.css";
import "../assets/stylesheets/custom_nodes.css";
import "prismjs/themes/prism-okaidia.css";
import "prism-themes/themes/prism-ghcolors.css";

// Prism.js imports
import "prismjs";
import "prismjs/components/prism-json";

// Rails and Stimulus imports
import * as Turbo from "@hotwired/turbo";
import "./controllers";

// React and related imports
import React from "react";
import { createRoot } from "react-dom/client";
import UiStudioChat from "./components/UiStudioChat";
import ManagementUI from "./components/ManagementUI";
import UICrewNodes from "./components/UICrew/UICrewNodes";
import CrewNodes from "./components/CrewView/CrewNodes";

// Third-party library imports
import "flowbite";
import "chartkick";
import Chart from "chart.js/auto";
import Choices from "choices.js";
import LocalTime from "local-time";

LocalTime.config.useFormat24 = true;
LocalTime.start();

// Initialize Turbo
Turbo.start();

// Function to render React components
const renderReactComponent = (component, props, targetId) => {
  const targetElement = document.getElementById(targetId);
  if (targetElement) {
    if (!targetElement._reactRoot) {
      targetElement._reactRoot = createRoot(targetElement);
    }
    targetElement._reactRoot.render(React.createElement(component, props));
  }
};

// Make React components and functions available globally
window.React = React;
window.createRoot = createRoot;
window.renderReactComponent = renderReactComponent;
window.UiStudioChat = UiStudioChat;
window.ManagementUI = ManagementUI;
window.UICrewNodes = UICrewNodes;
window.CrewNodes = CrewNodes;
window.Turbo = Turbo;
window.Chart = Chart;
window.LocalTime = LocalTime;

// Function to initialize or reinitialize components
const initializeComponents = () => {
  window.renderReactComponent = renderReactComponent;
  window.UiStudioChat = UiStudioChat;
  window.UICrewNodes = UICrewNodes;
  window.CrewNodes = CrewNodes;
  // Initialize Flowbite
  if (typeof window.initFlowbite === "function") {
    window.initFlowbite();
  }
};

// Event listeners for Turbo
document.addEventListener("turbo:load", initializeComponents);
document.addEventListener("turbo:render", initializeComponents);

// Initial call to initialize components
initializeComponents();
