// components/Column.jsx
import React from 'react';
import { Droppable } from '@hello-pangea/dnd';
import Card from './Card';
import AddCardForm from './AddCardForm';

const Column = ({ column, deleteCard, addCard, isAddingCard, setIsAddingCard, isDraggable, availableInputs, deployment, onCardClick, onError }) => {
  return (
    <div className="bg-gray-100 p-3 rounded-lg w-1/3 flex flex-col h-5/6 border-b border-gray-200 bg-gray-50">
      <h2 className="text-lg mb-2 text-secondary-color">{column.title}</h2>
      <div className="w-full h-px bg-gray-300 mb-4"></div>
      <Droppable droppableId={column.id} isDropDisabled={!isDraggable}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="flex-1 overflow-y-auto"
          >
            {column.cards && column.cards.map((card, index) => (
              <Card
                key={card.id}
                card={card}
                index={index}
                deleteCard={() => deleteCard(column.id, card.id)}
                isDraggable={isDraggable}
                onClick={() => onCardClick(card.id)}
                columnId={column.id}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {column.id === 'todo' && (
        <div className="mt-4">
          {isAddingCard ? (
            <AddCardForm
              onAddCard={(content) => addCard(column.id, content)}
              onCancel={() => setIsAddingCard(false)}
              availableInputs={availableInputs}
              deployment={deployment}
              onError={onError}
            />
          ) : (
            <button
              className="bg-primary-color hover:bg-primary-color-dark text-white py-2 px-4 rounded-md shadow-md w-full inline-flex items-center justify-center mt-4 text-sm"
              onClick={() => setIsAddingCard(true)}
            >
              Trigger Crew
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Column;
