import React, { useState, useEffect } from 'react';

const CrewInputsModal = ({ inputs, onClose, onSubmit }) => {
  const [inputValues, setInputValues] = useState({});
  const [errors, setErrors] = useState({});

  // Initialize input values when modal opens
  useEffect(() => {
    const initialValues = {};
    inputs.forEach(input => {
      initialValues[input.name] = input.defaultValue || '';
    });
    setInputValues(initialValues);
  }, [inputs]);

  const handleInputChange = (name, value) => {
    setInputValues(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: null
      }));
    }
  };

  const validateInputs = () => {
    const newErrors = {};
    inputs.forEach(input => {
      if (!inputValues[input.name] || !inputValues[input.name].trim()) {
        newErrors[input.name] = 'This field is required';
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateInputs()) {
      onSubmit(inputValues);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
            Enter Inputs
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="mt-2 space-y-4">
              {inputs.map((input, index) => (
                <div key={input.id || index} className="space-y-1">
                  <label
                    htmlFor={input.name}
                    className="block text-sm font-medium text-gray-700"
                  >
                    {input.name} <span className="text-red-500">*</span>
                  </label>
                  {input.type === 'text' && (
                    <div>
                      <input
                        type="text"
                        id={input.name}
                        name={input.name}
                        value={inputValues[input.name] || ''}
                        onChange={(e) => handleInputChange(input.name, e.target.value)}
                        className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm ${
                          errors[input.name]
                            ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
                        }`}
                        placeholder={input.placeholder}
                      />
                      {errors[input.name] && (
                        <p className="mt-1 text-sm text-red-600">
                          {errors[input.name]}
                        </p>
                      )}
                    </div>
                  )}
                  {/* Add more input types here if needed */}
                </div>
              ))}
            </div>
            <div className="flex justify-end gap-3 mt-4">
              <button
                type="button"
                onClick={onClose}
                className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="inline-flex justify-center rounded-md border border-transparent !bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:!bg-blue-700"
              >
                Start Execution
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CrewInputsModal;
