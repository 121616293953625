import React from 'react';
import PropTypes from 'prop-types';
import Message from './UIStudioMessage';
import { updateCrewPlan, generateCrewPlan as generateCrewPlanHelper, executePlan } from '../helpers/CrewPlanHelper';

class UiStudioChat extends React.Component {
  constructor(props) {
    super(props);
    console.log('Props:', props);
    const user_name = props.user_profile ? props.user_profile.first_name : 'there'
    const { formattedMessages, plans } = this.formatInitialMessages(
      props.messages || (props.chat && props.chat.messages) || [
        { html: `<p>Hey ${user_name}, what are you trying to accomplish and what kind of automation you want to build?<br/><br/>Keep in mind that the tools available for the agents will taking into account the environment variables defined, so make sure to set those.</p>`, sender: 'ai' }
      ]
    );

    this.state = {
      messages: formattedMessages,
      input: '',
      isLoading: false,
      aiAvatarPath: props.aiAvatarPath,
      chatId: props.chat ? props.chat.id : null,
      messageCount: props.messages ? props.messages.length : 1,
      isWaitingForResponse: false,
      plans: plans,
      isGeneratingResponse: false,
      planGenerated: false,
    };

    this.chatRef = React.createRef();

    // Bind methods
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.updatePlan = this.updatePlan.bind(this);
    this.generateCrewPlan = this.generateCrewPlan.bind(this);
    this.executePlan = this.executePlan.bind(this);
    this.exploreTemplates = this.exploreTemplates.bind(this);
    this.improveExistingProcess = this.improveExistingProcess.bind(this);
    this.optimizeBusinessMetrics = this.optimizeBusinessMetrics.bind(this);
    this.handleCellUpdate = this.handleCellUpdate.bind(this);
    this.attachCellUpdateListeners = this.attachCellUpdateListeners.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.toggleToolDropdown = this.toggleToolDropdown.bind(this);
    this.addTool = this.addTool.bind(this);
    this.removeTool = this.removeTool.bind(this);
  }

  handleInputChange(e) {
    this.setState({ input: e.target.value });
  }

  formatInitialMessages(messages) {
    const plans = [];
    const formattedMessages = messages.map(message => {
      if (message.sender === 'ai' && message.plan && message.plan.id) {
        let formattedHtml = message.html ? `<p>${message.html}</p>` : '';
        const plan = message.plan;
        try {
          formattedHtml += this.createToolUsageTable(plan.plan, plan.id);
          plans.push(plan);
        } catch (e) {
          console.log('Error parsing tool usage arguments:', e);
        }
        console.log('=====================');
        console.log('Message:', message);
        return { html: formattedHtml, sender: 'ai', crewPlan: !!message.plan };
      }
      return message;
    });

    return { formattedMessages, plans };
  }

  componentDidMount() {
    this.scrollToBottom();
    this.attachCellUpdateListeners();

    // Expose methods globally so the inline onclick calls in createToolUsageTable are routed correctly.
    window.toggleToolDropdown = this.toggleToolDropdown;
    window.addTool = this.addTool;
    window.removeTool = this.removeTool;
    window.executePlan = this.executePlan.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    // If messages or plans have changed, re-attach the event listeners
    if (
      prevState.messages !== this.state.messages ||
      prevState.plans !== this.state.plans
    ) {
      this.attachCellUpdateListeners();
    }
  }

  componentWillUnmount() {
    delete window.executePlan;
  }

  handleKeyPress(e) {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      this.sendMessage(e);
    }
  }

  sendMessage(e) {
    e.preventDefault();
    if (this.state.input.trim() === '' || this.state.isWaitingForResponse) return;

    const userMessage = this.state.input.trim();
    const newMessage = { text: userMessage, sender: 'user' };
    this.setState(prevState => ({
      messages: [...prevState.messages, newMessage],
      input: '',
      isLoading: true,
      isWaitingForResponse: true,
      isGeneratingResponse: true,
      messageCount: prevState.messageCount + 1,
      planGenerated: false  // Reset this when a new message is sent
    }), () => {
      this.getAIResponse(userMessage);
      this.scrollToBottom();
    });
  }

  getAIResponse(message) {
    fetch('/crewai_plus/ui_studio/chat', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({ message: message, chat_id: this.state.chatId })
    })
    .then(response => response.json())
    .then(data => {
      let aiMessageHtml = data.response;

      if (data.plan) {
        const newPlan = data.plan;
        aiMessageHtml += this.createToolUsageTable(newPlan.function.name, args, newPlan.plan_id);
      }

      const aiMessage = { html: aiMessageHtml, sender: 'ai' };
      this.setState(prevState => ({
        messages: [...prevState.messages, aiMessage],
        chatId: data.chat_id || prevState.chatId,
        messageCount: prevState.messageCount + 1,
        isGeneratingResponse: false
      }), this.scrollToBottom);

      if (!window.location.pathname.includes(data.chat_id)) {
        const newUrl = `${window.location.pathname}${window.location.pathname.endsWith('/') ? '' : '/'}${data.chat_id}`;
        window.history.pushState({ chatId: data.chat_id }, '', newUrl);
      }
    })
    .catch(error => {
      console.error('Error:', error);
      const errorMessage = { html: "<p>Sorry, there was an error processing your request.</p>", sender: 'ai' };
      this.setState(prevState => ({
        messages: [...prevState.messages, errorMessage],
        isGeneratingResponse: false
      }), this.scrollToBottom);
    })
    .finally(() => {
      this.setState({ isLoading: false, isWaitingForResponse: false });
    });
  }

  createToolUsageTable(plan, planId) {
    if (!plan || !plan.agents || !plan.tasks) return '';
    const createTable = (title, data, headers) => `
      <div class="mt-4 overflow-hidden rounded-lg shadow">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              ${headers.map(header => `<th class="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">${header}</th>`).join('')}
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            ${data.map((row, rowIndex) => `
              <tr>
                ${headers.map((header, cellIndex) => {
                  const field = header.toLowerCase().replace(' ', '_');
                  const value = row[field] || '';
                  const isRoleField = (title === 'Agents' && field === 'role') || (title === 'Tasks' && field === 'agent_role');
                  const isToolsField = field === 'tools';

                  if (isToolsField) {
                    // Check if this is the last row
                    const isLastRow = rowIndex === (data.length - 1);

                    return `
                      <td class="px-2 py-2 whitespace-normal text-sm text-gray-500">
                        <div class="relative group">
                          <div class="flex flex-wrap gap-2">
                            ${(value || []).map(tool => `
                              <span class="inline-flex items-center px-2 py-1 rounded-full text-[10px] font-medium bg-blue-100 text-blue-800">
                                ${tool}
                                <button class="ml-1 text-blue-600 hover:text-blue-800" onclick="removeTool('${title.toLowerCase()}', ${rowIndex}, '${tool}', '${planId}')">
                                  <svg class="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                  </svg>
                                </button>
                              </span>
                            `).join('')}
                            <div class="relative inline-block">
                              <button class="inline-flex items-center px-2 py-1 rounded-full text-[10px] font-medium bg-gray-100 text-gray-600 hover:bg-gray-200"
                                      onclick="toggleToolDropdown('${title.toLowerCase()}-${rowIndex}-${planId}')">
                                <svg class="w-3 h-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                </svg>
                                Add Tool
                              </button>
                              <div id="toolDropdown-${title.toLowerCase()}-${rowIndex}-${planId}"
                                   class="hidden absolute z-10 ${isLastRow ? 'bottom-full mb-2' : 'top-full mt-2'} rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 -ml-[138px]">
                                <div class="py-1 max-h-40 overflow-y-auto" role="menu" aria-orientation="vertical">
                                  ${this.props.tools_available.map(tool => `
                                    <button
                                      class="block w-full text-left px-4 py-2 text-xs text-gray-700 hover:bg-gray-100"
                                      title="${tool.description || 'No description available'}"
                                      onclick="addTool('${title.toLowerCase()}', ${rowIndex}, '${tool.name}', '${planId}')"
                                    >
                                      ${tool.name}
                                    </button>
                                  `).join('')}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    `;
                  }

                  return `
                    <td class="px-2 py-2 whitespace-normal text-sm text-gray-500">
                      <div class="relative group">
                        <div contenteditable="true"
                             class="text-xs outline-none focus:ring-2 focus:ring-primary-color rounded p-1 hover:bg-gray-100 transition-colors duration-200 cursor-text pr-6"
                             data-row-index="${rowIndex}"
                             data-field="${field}"
                             data-section="${title.toLowerCase()}"
                             data-plan-id="${planId}"
                             ${isRoleField ? `data-agent-role="${value}"` : ''}
                             data-cell-update>
                          ${value}
                        </div>
                        <svg class="w-4 h-4 text-gray-400 absolute top-1/2 right-1 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>
                      </div>
                    </td>
                  `;
                }).join('')}
              </tr>
            `).join('')}
          </tbody>
        </table>
      </div>
    `;

    const agentsTable = createTable('Agents', plan.agents, ['Role', 'Goal', 'Backstory', 'Tools']);
    const tasksTable = createTable('Tasks', plan.tasks, ['Description', 'Expected Output', 'Agent Role', 'Tools']);
    const droppedToolsInfo = plan.dropped_tools_info || [];

    return `
      <div class="w-full">
        ${droppedToolsInfo.length > 0 ? `
          <div class="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
            <div class="flex items-start justify-start flex-row">
              <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm m-0 font-bold text-yellow-800">Dropped Tools Notice</h3>
                ${droppedToolsInfo.map(droppedTool => `
                  <div class="mt-2">
                    <p class="text-sm text-yellow-700">
                      The following tools were dropped for ${
                        droppedTool.task_name
                          ? `task "${droppedTool.task_name.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim()}"`
                          : droppedTool.agent_role
                            ? `agent "${droppedTool.agent_role}"`
                            : 'unknown item'
                      } because they are not available:
                    </p>
                    <ul class="list-disc list-inside mt-1 text-sm text-red-600">
                      ${droppedTool.dropped.map(tool => `<li>${tool}</li>`).join('')}
                    </ul>
                  </div>
                `).join('')}
              </div>
            </div>
          </div>
        ` : ''}
        <div class="">
          <h4 class="text-md leading-6 font-bold text-gray-900">Agents</h4>
          ${agentsTable}
        </div>
        <div class="">
          <h4 class="text-md leading-6 font-bold text-gray-900">Tasks</h4>
          ${tasksTable}
        </div>
        <div class="mt-4">
          <button id="createCrewBtn-${planId}"
                  class="px-4 py-3 w-full bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300 flex items-center justify-center"
                  onclick="window.executePlan('${planId}')">
            <span>Generate Crew</span>
          </button>
        </div>
      </div>
    `;
  }

  handleCellUpdate(event) {
    const element = event.target;
    const planId = element.getAttribute('data-plan-id');
    const section = element.getAttribute('data-section');
    const rowIndex = parseInt(element.getAttribute('data-row-index'));
    const field = element.getAttribute('data-field');
    const newValue = element.textContent.trim();

    console.log(`Updating ${section} - ${field}: ${newValue}`);

    const oldValue = element.getAttribute('data-agent-role') || element.textContent.trim();

    this.setState(prevState => {
      const plans = [...prevState.plans];
      const planIndex = plans.findIndex(plan => plan.id == planId);
      if (planIndex === -1) return null;

      // Deep copy the plan to avoid mutating state directly
      const plan = JSON.parse(JSON.stringify(plans[planIndex]));

      // Update the specific field
      plan.plan[section][rowIndex][field] = newValue;

      if ((section === 'agents' && field === 'role') || (section === 'tasks' && field === 'agent_role')) {
        if (oldValue !== newValue) {
          if (section === 'agents') {
            // Update tasks that reference the old role
            plan.plan.tasks = plan.plan.tasks.map(task => {
              if (task.agent_role === oldValue) {
                task.agent_role = newValue;
              }
              return task;
            });
          } else if (section === 'tasks') {
            // Update agents that have the old role
            plan.plan.agents = plan.plan.agents.map(agent => {
              if (agent.role === oldValue) {
                agent.role = newValue;
              }
              return agent;
            });
            // Update other tasks with the same old role
            plan.plan.tasks = plan.plan.tasks.map((task, idx) => {
              if (task.agent_role === oldValue && idx !== rowIndex) {
                task.agent_role = newValue;
              }
              return task;
            });
          }

          // Update the DOM elements' data attributes and text content
          document.querySelectorAll('[data-agent-role]').forEach(el => {
            if (el.getAttribute('data-agent-role') === oldValue) {
              el.setAttribute('data-agent-role', newValue);
              if (el.getAttribute('data-field') === 'agent_role' || el.getAttribute('data-field') === 'role') {
                el.textContent = newValue;
              }
            }
          });

          this.notifyRoleChange(oldValue, newValue);
        }
      }

      plans[planIndex] = plan;

      // Call updatePlan with the updated plan
      this.updatePlan(planId, plan.plan);

      return { plans };
    });
  }

  notifyRoleChange(oldRole, newRole) {
    const message = `Agent role "${oldRole}" has been updated to "${newRole}" across all references.`;
    console.log(message);
    // Implement a user-friendly notification here, e.g., a toast message
  }

  attachCellUpdateListeners() {
    document.querySelectorAll('[data-cell-update]').forEach(element => {
      element.removeEventListener('blur', this.handleCellUpdate);
      element.addEventListener('blur', this.handleCellUpdate);
    });
  }

  scrollToBottom() {
    setTimeout(() => {
      if (this.chatRef.current) {
        this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
      }
    }, 0);
  }

  async generateCrewPlan() {
    if (this.state.isWaitingForResponse) return;

    this.setState({
      isLoading: true,
      isWaitingForResponse: true,
      isGeneratingResponse: true
    });

    try {
      const data = await generateCrewPlanHelper(this.state.chatId);
      let crewPlanHtml = data.response;
      let newPlan = data.plan;

      const processedPlan = {
        ...newPlan,
        plan: {
          ...newPlan.plan,
          // do any plan transformations here if needed
        }
      };

      // Build the HTML snippet once
      crewPlanHtml += this.createToolUsageTable(processedPlan.plan, processedPlan.id);

      // IMPORTANT: Attach the plan to the message object
      // so we can reference it in addTool/removeTool, etc.
      const crewPlanMessage = {
        html: crewPlanHtml,
        sender: 'ai',
        crewPlan: true,
        plan: processedPlan // ← store the plan (and plan id) here!
      };

      this.setState(prevState => ({
        messages: [...prevState.messages, crewPlanMessage],
        plans: [...prevState.plans, processedPlan],
        isGeneratingResponse: false,
        messageCount: prevState.messageCount + 1
      }), this.scrollToBottom);

    } catch (error) {
      console.error('Error:', error);
      const errorMessage = { html: '<p>Sorry, there was an error generating the crew plan.</p>', sender: 'ai' };
      this.setState(prevState => ({
        messages: [...prevState.messages, errorMessage]
      }), this.scrollToBottom);
    } finally {
      this.setState({
        isLoading: false,
        isWaitingForResponse: false,
        isGeneratingResponse: false
      }, this.scrollToBottom);
    }
  }

  async updatePlan(planId, updatedPlan) {
    console.log('[updatePlan] About to send updated plan to backend:', { planId, updatedPlan });
    try {
      const data = await updateCrewPlan(this.state.chatId, planId, updatedPlan);
      console.log('[updatePlan] Plan updated successfully:', data);
    } catch (error) {
      console.error('[updatePlan] Error updating plan:', error);
      // Show user-facing error, if needed.
    }
  }

  async executePlan(planId){
    if (this.state.isWaitingForResponse) return;

    this.setState({ isLoading: true, isWaitingForResponse: true });

    // Update the button state immediately
    const button = document.getElementById(`createCrewBtn-${planId}`);
    button.disabled = true;
    button.classList.add('opacity-50', 'cursor-not-allowed');
    button.innerHTML = `
      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      Creating... Might take up to 30 seconds.
    `;

    try {
      const data = await executePlan(this.state.chatId, planId);
      if (data.crew.id) {
        window.location.href = `/crewai_plus/ui_studio/ui_crews/${data.crew.id}`;
      } else {
        const executionMessage = { html: `<p>${data.message}</p>`, sender: 'ai' };
        this.setState(prevState => ({
          messages: [...prevState.messages, executionMessage],
          messageCount: prevState.messageCount + 1
        }), this.scrollToBottom);
      }
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = { html: "<p>Sorry, there was an error executing the crew plan.</p>", sender: 'ai' };
      this.setState(prevState => ({
        messages: [...prevState.messages, errorMessage]
      }), this.scrollToBottom);

      // Reset the button state on error
      button.disabled = false;
      button.classList.remove('opacity-50', 'cursor-not-allowed');
      button.textContent = 'Create Crew';
    } finally {
      this.setState({ isLoading: false, isWaitingForResponse: false }, this.scrollToBottom);
    }
  }

  exploreTemplates() {
    // TODO: Implement explore templates
    // 1. Get list of templates
    // 2. Display list of templates
    // 3. Allow user to select template
    // 4. Generate crew plan
    console.log('Explore Templates');
  }

  improveExistingProcess() {
    const userMessage = "Can you help me improve an existing business process? What information do you need?";
    const newMessage = { text: userMessage, sender: 'user' };
    this.setState(prevState => ({
      messages: [...prevState.messages, newMessage],
      input: '',
      isLoading: true,
      isWaitingForResponse: true,
      messageCount: prevState.messageCount + 1
    }), () => {
      this.getAIResponse(userMessage);
      this.scrollToBottom();
    });
  }

  optimizeBusinessMetrics() {
    const userMessage = "Can you help me optimize my business metrics? What information do you need to help me do that?";
    const newMessage = { text: userMessage, sender: 'user' };
    this.setState(prevState => ({
      messages: [...prevState.messages, newMessage],
      input: '',
      isLoading: true,
      isWaitingForResponse: true,
      messageCount: prevState.messageCount + 1
    }), () => {
      this.getAIResponse(userMessage);
      this.scrollToBottom();
    });
  }

  displayInitialOptions() {
    return(
      <div className="px-2 mb-2 flex space-x-2">
        <button
          onClick={this.exploreTemplates}
          className={`w-full px-4 py-2 bg-gray-400 text-sm text-white rounded-lg hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2`}
        >
          Explore Templates
        </button>
        <button
          onClick={this.improveExistingProcess}
          className={`w-full px-4 py-2 bg-gray-400 text-sm text-white rounded-lg hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2`}
        >
          Improve an Existing Process
        </button>
        <button
          onClick={this.optimizeBusinessMetrics}
          className={`w-full px-4 py-2 bg-gray-400 text-sm text-white rounded-lg hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2`}
        >
          Optimize Business Metrics
        </button>
      </div>
    )
  }

  displayPlanGenerationButton() {
    const { isWaitingForResponse, planGenerated, messageCount } = this.state;

    // Only display the button if a plan hasn't been generated and there are at least 5 messages
    if (planGenerated || messageCount < 5) {
      return null;
    }

    return (
      <div className="px-2 mb-2">
        <button
          onClick={this.generateCrewPlan}
          disabled={isWaitingForResponse}
          className={`w-full px-4 py-2 bg-primary-color text-sm text-white rounded-lg hover:bg-primary-color-dark focus:outline-none focus:ring-2 focus:ring-primary-color focus:ring-offset-2 ${isWaitingForResponse ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {isWaitingForResponse ? 'Generating...' : 'Generate Crew Plan'}
        </button>
      </div>
    );
  }

  /*
    Toggles the visibility of the "Add Tool" dropdown menu for the given dropdownId.
    This example simply toggles the "hidden" class on the dropdown element.
  */
  toggleToolDropdown(dropdownId) {
    console.log('[toggleToolDropdown] dropdownId:', dropdownId);
    const dropdown = document.getElementById(`toolDropdown-${dropdownId}`);
    if (dropdown) {
      dropdown.classList.toggle('hidden');
    }
  }

  /*
    Adds a tool to the specified row in our plan. We:
    1. Deep-clone the plan in state.
    2. Update the row's tools array.
    3. (Optional) Regenerate the plan's HTML snippet so the UI updates immediately.
    4. Call updatePlan to sync with the backend.
  */
  addTool(section, rowIndex, toolName, planId) {
    console.log('[addTool] Invoked with:', { section, rowIndex, toolName, planId });
    const numericPlanId = parseInt(planId, 10);

    // Immediately update the UI by adding the tool to the table
    const toolElement = document.querySelector(`#toolDropdown-${section}-${rowIndex}-${planId}`).closest('td');
    if (toolElement) {
      const toolsContainer = toolElement.querySelector('.flex.flex-wrap.gap-2');
      const newToolSpan = document.createElement('span');
      newToolSpan.className = 'inline-flex items-center px-2 py-1 rounded-full text-[10px] font-medium bg-blue-100 text-blue-800';
      newToolSpan.innerHTML = `
        ${toolName}
        <button class="ml-1 text-blue-600 hover:text-blue-800 text-[10px]" onclick="removeTool('${section}', ${rowIndex}, '${toolName}', '${planId}')">
          <svg class="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      `;
      toolsContainer.insertBefore(newToolSpan, toolsContainer.lastElementChild);
    }

    // Hide the dropdown after selection
    const dropdown = document.getElementById(`toolDropdown-${section}-${rowIndex}-${planId}`);
    if (dropdown) {
      dropdown.classList.add('hidden');
    }

    // Update state and make backend request
    this.setState(prevState => {
      const plans = [...prevState.plans];
      const planIndex = plans.findIndex(plan => plan.id === numericPlanId);

      if (planIndex === -1) {
        console.warn(`[addTool] No plan found for planId=${numericPlanId}`);
        return null;
      }

      const clonedPlan = JSON.parse(JSON.stringify(plans[planIndex]));
      const row = clonedPlan.plan[section][rowIndex] || {};
      if (!row.tools) {
        row.tools = [];
      }
      if (!row.tools.includes(toolName)) {
        row.tools.push(toolName);
        console.log(`[addTool] Added "${toolName}" to ${section} row #${rowIndex}`);
      }

      plans[planIndex] = clonedPlan;

      // Update backend
      this.updatePlan(numericPlanId, clonedPlan.plan);

      return { plans };
    });
  }

  /*
    Removes the specified tool from the given row. Same flow as addTool:
    1. Clone the plan in state.
    2. Filter out the specified tool.
    3. Regenerate the relevant message's HTML snippet so the UI updates immediately.
    4. Call updatePlan to sync with the backend.
  */
  removeTool(section, rowIndex, toolName, planId) {
    console.log('[removeTool] Invoked with:', { section, rowIndex, toolName, planId });
    const numericPlanId = parseInt(planId, 10);

    // Immediately update the UI by removing the tool span
    const toolElement = document.querySelector(`#toolDropdown-${section}-${rowIndex}-${planId}`).closest('td');
    if (toolElement) {
      const toolSpan = Array.from(toolElement.querySelectorAll('span')).find(span =>
        span.textContent.trim().startsWith(toolName)
      );
      if (toolSpan) {
        toolSpan.remove();
      }
    }

    // Update state and make backend request
    this.setState(prevState => {
      const plans = [...prevState.plans];
      const planIndex = plans.findIndex(plan => plan.id === numericPlanId);

      if (planIndex === -1) {
        console.warn(`[removeTool] No plan found for planId=${numericPlanId}`);
        return null;
      }

      const clonedPlan = JSON.parse(JSON.stringify(plans[planIndex]));
      const row = clonedPlan.plan[section][rowIndex] || {};
      if (row.tools) {
        const beforeCount = row.tools.length;
        row.tools = row.tools.filter(t => t !== toolName);
        if (row.tools.length !== beforeCount) {
          console.log(`[removeTool] Removed "${toolName}" from ${section} row #${rowIndex}`);
        }
      }

      plans[planIndex] = clonedPlan;

      // Update backend
      this.updatePlan(numericPlanId, clonedPlan.plan);

      return { plans };
    });
  }

  render() {
    const { messages, input, isLoading, messageCount, isWaitingForResponse, aiAvatarPath, isGeneratingResponse } = this.state;
    return (
      <ul role="list" className="rid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-1 xl:gap-x-8 w-full h-full">
        <div className='h-full'>
          <li className="overflow-hidden rounded-xl border border-gray-200">
            <div className='flex flex-col' style={{ "height": "95dvh" }}>
              <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-4 py-3 justify-between">
                <div className="flex items-center">
                  <div className="text-sm font-medium leading-6 text-gray-900 ml-3">
                    Configure your Crew
                  </div>
                </div>
              </div>

              <div className="overflow-y-auto p-2" ref={this.chatRef}>
                {messages && messages.map((message, index) => (
                  <Message key={index} message={message} aiAvatarPath={aiAvatarPath} />
                ))}
                {isGeneratingResponse && (
                  <div className="flex items-center justify-center space-x-2 text-gray-500 italic p-3">
                      <svg className="animate-spin h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <span className='text-sm animate-pulse'>Thinking...</span>
                  </div>
                )}
              </div>

              {messageCount == 1 && (
                // this.displayInitialOptions()
                true
              )}

              {this.displayPlanGenerationButton()}

              <form onSubmit={this.sendMessage} className="mt-auto mb-2 flex px-2">
                <textarea
                  value={input}
                  onChange={this.handleInputChange}
                  onKeyPress={this.handleKeyPress}
                  disabled={isWaitingForResponse}
                  className={`text-sm w-4/5 mr-4 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-color focus:border-primary-color ${isWaitingForResponse ? 'opacity-50 cursor-not-allowed' : ''}`}
                  rows="4"
                  placeholder="Describe what you are trying to accomplish and what kind of automation you want to build."
                />
                <button
                  type="submit"
                  disabled={isLoading || isWaitingForResponse}
                  className={`w-1/5 px-4 py-2 !bg-primary-color text-sm text-white rounded-lg hover:!bg-primary-color-dark focus:outline-none focus:ring-2 focus:ring-primary-color focus:ring-offset-2 ${(isLoading || isWaitingForResponse) ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  {isWaitingForResponse ? 'Waiting...' : (isLoading ? 'Sending...' : 'Send')}
                </button>
              </form>
            </div>
          </li>
        </div>
      </ul>
    );
  }
}

UiStudioChat.propTypes = {
  chat: PropTypes.shape({
    id: PropTypes.number,
    messages: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      html: PropTypes.string,
      sender: PropTypes.oneOf(['ai', 'user']),
      tool_calls: PropTypes.array
    }))
  }),
  messages: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    html: PropTypes.string,
    sender: PropTypes.oneOf(['ai', 'user']),
    tool_calls: PropTypes.array
  }))
};

export default UiStudioChat;
