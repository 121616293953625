import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['status']
  static values = {
    url: String,
    turboFrame: String
  }

  change(event) {
    const checkbox = event.currentTarget
    // Temporarily disable the toggle during the request
    checkbox.disabled = true

    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute('content'),
        'Accept': 'text/html'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        // We don't need the returned HTML since we're reloading the page
        return response.text()
      })
      .then(() => {
        window.location.reload()
      })
      .catch(error => {
        console.error('Error toggling internal LLM:', error)
      })
      .finally(() => {
        checkbox.disabled = false
      })
  }
}