import React, { memo } from 'react';
import { Handle, Position } from '@xyflow/react';

// Renamed component to CrewInputsNode
const CrewInputsNode = memo(({ data, isConnectable }) => {
  const handleClick = () => {
    // We'll implement this once you confirm the modal requirements
    if (data.onInputsClick) {
      data.onInputsClick(data.inputs);
    }
  };

  return (
    <div
      className="react-flow__node-default gradient cursor-pointer"
      onClick={handleClick}
    >
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      <div>Inputs</div>
    </div>
  );
});

// Updated export name
export default CrewInputsNode;
