import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';

const CrewResultModal = ({ result, onClose, title = 'Crew Execution Result' }) => {
  const [viewMode, setViewMode] = useState('markdown'); // 'markdown', 'raw', 'json'

  const renderContent = () => {
    switch (viewMode) {
      case 'markdown':
        return (
          <div className="prose prose-sm max-w-none">
            <ReactMarkdown>{result}</ReactMarkdown>
          </div>
        );
      case 'raw':
        return (
          <pre className="whitespace-pre-wrap font-mono text-sm">
            {result}
          </pre>
        );
      case 'json':
        return (
          <pre className="whitespace-pre-wrap font-mono text-sm">
            {typeof result === 'object' ? JSON.stringify(result, null, 2) : 'Not available in JSON format'}
          </pre>
        );
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-3/4 shadow-lg rounded-md bg-white">
        <div className="mt-3">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {title}
            </h3>
            <div className="flex gap-2">
              <ViewToggleButton
                active={viewMode === 'markdown'}
                onClick={() => setViewMode('markdown')}
              >
                Markdown
              </ViewToggleButton>
              <ViewToggleButton
                active={viewMode === 'raw'}
                onClick={() => setViewMode('raw')}
              >
                Raw
              </ViewToggleButton>
              {/* Uncomment when JSON view is needed
              <ViewToggleButton
                active={viewMode === 'json'}
                onClick={() => setViewMode('json')}
              >
                JSON
              </ViewToggleButton>
              */}
            </div>
          </div>
          <div className="mt-2 max-h-96 overflow-y-auto bg-gray-50 p-4 rounded">
            {renderContent()}
          </div>
          <div className="flex justify-end mt-4">
            <button
              onClick={onClose}
              className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Helper component for view toggle buttons
const ViewToggleButton = ({ active, onClick, children }) => (
  <button
    onClick={onClick}
    className={`px-3 py-1 text-sm font-medium rounded-md transition-colors
      ${active
        ? 'bg-blue-100 text-blue-700 hover:bg-blue-200'
        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
      }`}
  >
    {children}
  </button>
);

export default CrewResultModal;
