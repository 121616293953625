import React, { useCallback, useEffect, useState } from 'react';
import {
  ReactFlow,
  ReactFlowProvider,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
} from '@xyflow/react';
import CrewTaskNode from './CrewTaskNode';
import CrewInputsNode from './CrewInputsNode';
import CrewOutputNode from './CrewOutputNode';
import CrewConnectionLine from './CrewConnectionLine';
import { CrewAnimatedEdge } from './CrewAnimatedEdge';
import CrewTaskModal from './CrewTaskModal';
import CrewInputsModal from './CrewInputsModal';
import CrewResultModal from './CrewResultModal';
import ExecutionLogPanel from './ExecutionLogPanel';

const nodeTypes = {
  taskNode: CrewTaskNode,
  inputsNode: CrewInputsNode,
  outputNode: CrewOutputNode,
};

const edgeTypes = {
  animated: CrewAnimatedEdge,
};

const POLLING_INTERVAL = 2000; // 2 seconds
const TIMEOUT_DURATION = 120000;

const convertTemplateToRegex = (template) => {
  // Replace {variableName} with a regex pattern that matches any characters except newlines
  return template.replace(/\{[^}]+\}/g, '(.+?)');
};

const compareDescriptions = (templateDesc, executedDesc) => {
  // Convert template description to regex pattern
  const regexPattern = convertTemplateToRegex(templateDesc);
  const regex = new RegExp(regexPattern);

  // Test if the executed description matches the pattern
  return regex.test(executedDesc);
};

// Create a wrapper component that uses the ReactFlow hooks
function CrewNodesContent({ crew }) {
  const { fitView } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [showInputsModal, setShowInputsModal] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [executionStatus, setExecutionStatus] = useState(null);
  const [executionResult, setExecutionResult] = useState(null);
  const [showResultModal, setShowResultModal] = useState(false);
  const [completedTasks, setCompletedTasks] = useState(new Set());
  const [pendingTasks, setPendingTasks] = useState(new Set());
  const [isLogPanelOpen, setIsLogPanelOpen] = useState(false);

  const handleNodeClick = useCallback((taskData) => {
    setSelectedTask(taskData);
  }, []);


  const handleInputsClick = () => {
    setShowInputsModal(true);
  };

  // Update the pollStatus function
  const pollStatus = useCallback(async (kickoffId, startTime) => {
    if (Date.now() - startTime > TIMEOUT_DURATION) {
      console.log('Polling timeout reached');
      setIsPolling(false);
      setExecutionStatus(null);
      setExecutionResult(null);
      return;
    }

    try {
      const response = await fetch(`/crewai_plus/crew_ui/${crew.id}/execution?kickoff_id=${kickoffId}`);
      const data = await response.json();

      console.log('Execution response:', data);

      setExecutionStatus(data.status);

      // Helper function to transform task data
      const transformTask = task => ({
        id: task.id,
        key: task.key,
        description: task.formatted_description,
        expected_output: task.formatted_expected_output,
        output: task.output,
        agent_role: task.agent_role
      });

      // Update completed and pending tasks
      const completedTasksSet = new Set(
        data.tasks
          .filter(task => task.status === 'completed')
          .map(transformTask)
      );
      setCompletedTasks(completedTasksSet);

      const pendingTasksSet = new Set(
        data.tasks
          .filter(task => task.status === 'pending_run')
          .map(transformTask)
      );
      setPendingTasks(pendingTasksSet);

      // Update nodes to reflect completion status
      setNodes(prevNodes =>
        prevNodes.map(node => {
          if (node.type === 'taskNode') {
            const isCompleted = Array.from(completedTasksSet).some(task =>
              task.key === node.data.key
            );
            const isRunning = Array.from(pendingTasks).some(task =>
              task.key === node.data.key
            );
            return {
              ...node,
              data: {
                ...node.data,
                isCompleted,
                isRunning
              }
            };
          } else if (node.type === 'outputNode' && (data.status === 'completed' || data.status === 'error')) {
            return {
              ...node,
              data: {
                ...node.data,
                executionStatus: data.status,
                result: data.output,
                isCompleted: data.status === 'completed'
              }
            };
          }
          return node;
        })
      );

      // Check if execution is still in progress or pending
      if (data.status === 'pending' || data.status === 'pending_run' || data.status === 'pending_human_input') {
        setTimeout(() => pollStatus(kickoffId, startTime), POLLING_INTERVAL);
      } else if (data.status === 'completed' || data.status === 'error') {
        setIsPolling(false);
        setExecutionResult(data.output);
      }
    } catch (error) {
      console.error('Error polling execution:', error);
      setIsPolling(false);
      setExecutionStatus(null);
      setExecutionResult(null);
    }
  }, [crew.id, setNodes]);

  // Create a handler for toggling the log panel that includes fitView
  const handleLogPanelToggle = useCallback(() => {
    setIsLogPanelOpen(prev => {
      const newIsOpen = !prev;
      // If we're opening the panel, fit the view after a short delay
      // to allow the panel animation to complete
      if (newIsOpen) {
        setTimeout(() => {
          fitView({ padding: 0.1, duration: 200 });
        }, 300);
      }
      return newIsOpen;
    });
  }, [fitView]);

  // Update the handleKickoff function
  const handleKickoff = async (inputValues) => {
    try {
      // Clear previous execution state
      setExecutionStatus(null);
      setExecutionResult(null);
      setCompletedTasks(new Set());
      setPendingTasks(new Set());

      const response = await fetch(`/crewai_plus/crew_ui/${crew.id}/kickoff`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({ inputs: inputValues })
      });

      const data = await response.json();
      if (data.status === 'success') {
        setShowInputsModal(false);
        console.log('Kickoff response:', data);

        setIsPolling(true);
        setExecutionStatus('pending');
        setIsLogPanelOpen(true);
        setTimeout(() => {
          fitView({ padding: 0.1, duration: 200 });
        }, 300);
        pollStatus(data.kickoff_response.kickoff_id, Date.now());
      }
    } catch (error) {
      console.error('Error kicking off crew:', error);
      setIsPolling(false);
      setExecutionStatus(null);
    }
  };

  // Update edge creation to include both states
  const isExecuting = executionStatus === 'pending' ||
                     executionStatus === 'pending_run' ||
                     executionStatus === 'pending_human_input';

  useCallback((source, target) => ({
    id: `${source}-${target}`,
    source,
    target,
    type: 'animated',
    data: { isAnimated: isExecuting }
  }), [isExecuting]);

  // Update the edges effect to check for both states
  useEffect(() => {
    setEdges(currentEdges =>
      currentEdges.map(edge => ({
        ...edge,
        data: { ...edge.data, isAnimated: isExecuting }
      }))
    );
  }, [executionStatus, setEdges]);

  // Remove the duplicate onConnect callback and keep only this one
  const onConnect = useCallback(
    (params) => {
      if (params.source !== params.target) {
        setEdges((eds) => {
          const newEdge = {
            ...params,
            type: 'animated',
            data: { isAnimated: isExecuting }
          };
          return addEdge(newEdge, eds);
        });
      }
    },
    [setEdges, isExecuting]
  );

  // Add handler for result click
  const handleResultClick = useCallback((result) => {
    setShowResultModal(true);
  }, []);

  useEffect(() => {
    if (crew) {
      const newNodes = [];
      const newEdges = [];
      const sourceToTargets = {};
      const targetToSources = {};

      let previousTaskName = null;

      // First pass: Create all nodes and build the connection maps
      crew.tasks.forEach((task, index) => {
        const position = { x: (index + 1) * 300, y: 0 };
        const isCompleted = Array.from(completedTasks).some(completedTask =>
          completedTask.key === task.key
        );
        const isRunning = Array.from(pendingTasks).some(pendingTask =>
          pendingTask.key === task.key
        );

        // Build connection maps based on task context
        if (task.context && task.context.length > 0) {
          task.context.forEach(sourceName => {
            // Source to targets mapping
            if (!sourceToTargets[sourceName]) {
              sourceToTargets[sourceName] = [];
            }
            sourceToTargets[sourceName].push(task.name);

            // Target to sources mapping
            if (!targetToSources[task.name]) {
              targetToSources[task.name] = [];
            }
            targetToSources[task.name].push(sourceName);
          });
        }

        let isAsync = task.async_execution || false;

        // Apply Rule 1: Final tasks should never be async
        const hasOutgoingConnections = crew.tasks.some(t =>
          t.context && t.context.includes(task.name)
        );
        if (!hasOutgoingConnections) {
          isAsync = false;
        }
        newNodes.push({
          id: task.name,
          type: 'taskNode',
          position: position,
          data: {
            id: task.name,
            name: task.name,
            description: task.description,
            agent: task.agent_role || task.agent,
            expected_output: task.expected_output,
            agents: crew.agents,
            tools: task.tools || [],
            onClick: handleNodeClick,
            context: task.context || [],
            async_execution: isAsync,
            isCompleted: isCompleted,
            isRunning: isRunning,
          },
        });

        // Create edges based on context
        if (task.context && task.context.length > 0) {
          task.context.forEach(sourceName => {
            newEdges.push({
              id: `${sourceName}-${task.name}`,
              source: sourceName,
              target: task.name,
              type: 'animated',
              data: { isAnimated: isExecuting }
            });
          });
        } else if (previousTaskName && task.context !== null) {
          newEdges.push({
            id: `${previousTaskName}-${task.name}`,
            source: previousTaskName,
            target: task.name,
            type: 'animated',
            data: { isAnimated: isExecuting }
          });
        }

        previousTaskName = task.context !== null ? task.name : null;
      });

      // Second pass: Apply Rule 2 for sibling connections
      const finalNodes = newNodes.map(node => {
        if (node.data.async_execution) {
          const nodeSources = targetToSources[node.data.name] || [];
          let shouldBeAsync = true;

          nodeSources.forEach(source => {
            const sourceTargets = sourceToTargets[source] || [];
            if (sourceTargets.length > 1) {
              const siblings = sourceTargets.filter(target => target !== node.data.name);
              siblings.forEach(sibling => {
                // Check for connections between siblings
                const hasConnectionToSibling = sourceToTargets[node.data.name] && sourceToTargets[node.data.name].includes(sibling);
                const hasConnectionFromSibling = crew.tasks.find(t =>
                  t.name === sibling && t.context && t.context.includes(node.data.name)
                );

                if (hasConnectionToSibling || hasConnectionFromSibling) {
                  shouldBeAsync = false;
                }
              });
            }
          });

          return {
            ...node,
            data: {
              ...node.data,
              async_execution: shouldBeAsync
            }
          };
        }
        return node;
      });

      const lastTaskNode = finalNodes[finalNodes.length - 1];

      // Add input node
      finalNodes.push({
        id: 'inputsNode',
        type: 'inputsNode',
        position: { x: 300, y: -100 },
        data: {
          inputs: crew.inputs,
          onInputsClick: handleInputsClick
        },
      });
      newEdges.push({
        id: `${'inputsNode'}-${finalNodes[0].id}`,
        source: 'inputsNode',
        target: finalNodes[0].id,
        type: 'animated',
        data: { isAnimated: isExecuting }
      });

      // Add output node with all required props
      finalNodes.push({
        id: 'outputNode',
        type: 'outputNode',
        position: { x: (finalNodes.length - 1) * 300, y: 300 },
        data: {
          id: 'outputNode',
          name: 'Output',
          executionStatus: executionStatus,
          result: executionResult,
          onResultClick: handleResultClick,
          isCompleted: executionStatus === 'completed'
        },
      });
      newEdges.push({
        id: `${lastTaskNode.id}-${'outputNode'}`,
        source: lastTaskNode.id,
        target: 'outputNode',
        type: 'animated',
        data: { isAnimated: isExecuting }
      });

      setNodes(finalNodes);
      setEdges(newEdges);
    }
  }, [crew, handleNodeClick, executionStatus, executionResult, handleResultClick, completedTasks, pendingTasks]);

  return (
    <div className="relative flex w-full h-full overflow-hidden">
      <div className="flex-1 h-full">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          connectionLineComponent={CrewConnectionLine}
          connectionLineType="smoothstep"
          fitView
          nodesDraggable={true}
          nodesConnectable={true}
          elementsSelectable={true}
          edgesFocusable={true}
          selectNodesOnDrag={false}
          connectionMode="loose"
        >
          <Controls />
          <Background variant="dots" gap={12} size={1} />
        </ReactFlow>
      </div>

      <ExecutionLogPanel
        isOpen={isLogPanelOpen}
        onToggle={handleLogPanelToggle}
        executionStatus={executionStatus}
        executionResult={executionResult}
        completedTasks={completedTasks}
        pendingTasks={pendingTasks}
      />

      {selectedTask && (
        <CrewTaskModal
          task={selectedTask}
          onClose={() => setSelectedTask(null)}
          crewId={crew.id}
        />
      )}
      {showInputsModal && (
        <CrewInputsModal
          inputs={crew.inputs}
          onClose={() => setShowInputsModal(false)}
          onSubmit={handleKickoff}
        />
      )}
      {showResultModal && (
        <CrewResultModal
          result={executionResult}
          onClose={() => setShowResultModal(false)}
        />
      )}
    </div>
  );
}

// Main component that provides the ReactFlow context
function CrewNodes({ crew }) {
  return (
    <ReactFlowProvider>
      <CrewNodesContent crew={crew} />
    </ReactFlowProvider>
  );
}

export default CrewNodes;
