import React, { useRef, useEffect, useState } from 'react';
import CrewResultModal from './CrewResultModal';

const MAX_OUTPUT_LENGTH = 20;

const TaskOutput = ({ output, onClick }) => (
  <div
    className="mt-2 border-t border-blue-100 pt-2 cursor-pointer hover:bg-blue-50 rounded transition-colors"
    onClick={onClick}
  >
    <p className="text-xs text-blue-700 font-medium mb-1">Output (click to view full):</p>
    <p className="text-xs text-blue-600 whitespace-pre-wrap">
      {output.length > MAX_OUTPUT_LENGTH
        ? `${output.slice(0, MAX_OUTPUT_LENGTH)}...`
        : output}
    </p>
  </div>
);

const ExecutionLogPanel = ({
  isOpen,
  onToggle,
  executionStatus,
  executionResult,
  completedTasks,
  pendingTasks
}) => {
  const contentRef = useRef(null);
  const [selectedTaskOutput, setSelectedTaskOutput] = useState(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [executionResult, completedTasks, pendingTasks]);

  return (
    <React.Fragment>
      <div className="relative h-full">
        {/* Toggle Button - Now with correct direction */}
        <button
          onClick={onToggle}
          className={`absolute top-4 -left-8 p-1 bg-white hover:bg-gray-100 rounded-l border border-r-0 border-gray-200`}
        >
          <svg
            className={`h-5 w-5 transform ${isOpen ? 'rotate-180' : ''}`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>

        <div
          className={`h-full bg-white border-l border-gray-200 transition-all duration-300 ease-in-out overflow-hidden ${
            isOpen ? 'w-[400px]' : 'w-0'
          }`}
        >
          <div className={`h-full flex flex-col`}>
            <div className="flex-none p-4 border-b border-gray-200">
              <h2 className="text-lg font-semibold">Execution Log</h2>
            </div>

            <div
              ref={contentRef}
              className="flex-1 p-4 overflow-y-auto"
            >
              {/* Status Badge */}
              <div className="mb-4">
                <span className={`inline-flex items-center rounded-md px-2.5 py-0.5 text-sm font-medium
                  ${executionStatus === 'pending_run' ? 'bg-yellow-100 text-yellow-800' :
                    executionStatus === 'pending_human_input' ? 'bg-blue-100 text-blue-800' :
                    executionStatus === 'completed' ? 'bg-green-100 text-green-800' :
                    executionStatus === 'error' ? 'bg-red-100 text-red-800' :
                    'bg-gray-100 text-gray-800'}`}>
                  {executionStatus ? executionStatus.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : 'Not Started'}
                </span>
              </div>

              {/* Completed Tasks with Output */}
              {completedTasks.size > 0 && (
                <div className="mb-4">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">Completed Tasks</h3>
                  <div className="space-y-2">
                    {Array.from(completedTasks).map((task, index) => (
                      <div key={index} className="rounded-md bg-green-50 p-3">
                        <p className="text-sm font-medium text-green-800 mb-1">
                          {task.description}
                        </p>
                        <p className="text-xs text-green-600 mb-2">
                          Agent: {task.agent_role}
                        </p>
                        {task.output && (
                          <TaskOutput
                            output={task.output}
                            onClick={() => setSelectedTaskOutput({
                              output: task.output,
                              description: task.description
                            })}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Running tasks */}
              {pendingTasks.size > 0 && (
                <div className="mb-4">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">Running Tasks</h3>
                  <div className="space-y-2">
                    {Array.from(pendingTasks).map((task, index) => (
                      <div key={index} className="rounded-md bg-[#EFF6FF] p-3">
                        <p className="text-sm font-medium text-blue-800 mb-1">
                          {task.description}
                        </p>
                        <p className="text-xs text-blue-600 mb-2">
                          Agent: {task.agent_role}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Final Result */}
              {executionResult && (
                <div className="mb-4">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">Final Result</h3>
                  <div className="rounded-md bg-gray-50 p-3">
                    <pre className="text-sm text-gray-800 whitespace-pre-wrap">
                      {typeof executionResult === 'string'
                        ? executionResult
                        : JSON.stringify(executionResult, null, 2)}
                    </pre>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Task Output Modal */}
      {selectedTaskOutput && (
        <CrewResultModal
          result={selectedTaskOutput.output}
          title={`Task Output: ${selectedTaskOutput.description}`}
          onClose={() => setSelectedTaskOutput(null)}
        />
      )}
    </React.Fragment>
  );
};

export default ExecutionLogPanel;
