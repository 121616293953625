import React from 'react';
import PropTypes from 'prop-types';

const Message = ({ message, aiAvatarPath}) => {
  if (message.crewPlan) {
    return (
      <div className="p-4 text-sm">
        <hr/><br/>
        <div className="text-xl font-bold text-gray-900">Crew Plan</div>
        <p className="text-gray-700 text-sm font-italic bg-gray-100 p-4 rounded-lg">
          Here is the plan for the Crew. You can edit the plan by clicking on the cells.<br/>
          Feel free to keep tweaking over chat as well and generating new ones.
        </p>
        <div dangerouslySetInnerHTML={{ __html: message.html }} />
      </div>
    )
  }
  else {
    return (
      <div className={`flex items-start p-4 my-2 w-2/3 min-w-[450px] ${message.sender === 'ai' ? 'bg-gray-100' : 'bg-primary-color-lightest'} rounded-lg ${message.sender === 'user' ? 'ml-auto !pl-10' : '!pr-10'}`}>
        <div className='overflow-x-auto'>
          <div className="text-sm font-bold text-gray-900">{message.sender === 'ai' ? 'Crew Assistant' : 'You'}</div>
          <div className={`mt-1 text-sm text-gray-700`}>
            {message.sender === 'ai' ? (
              <div dangerouslySetInnerHTML={{ __html: message.html }} className='w-full overflow-x-auto' />
            ) : (
              message.text && message.text.split('\n').map((line, i) => (
                <React.Fragment key={i}>
                  {line}
                  {i < message.text.split('\n').length - 1 && <br />}
                </React.Fragment>
              ))
            )}
          </div>
        </div>
      </div>
    )
  }
};

Message.propTypes = {
  message: PropTypes.shape({
    text: PropTypes.string,
    html: PropTypes.string,
    crewPlan: PropTypes.bool,
    sender: PropTypes.oneOf(['ai', 'user']).isRequired,
  }).isRequired,
};

export default Message;
