import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["status", "steps", "container"]
  static values = {
    deploymentId: String,
    interval: Number
  }

  connect() {
    this.checkStatus()
    this.startPolling()
  }

  disconnect() {
    if (this.intervalId) clearInterval(this.intervalId)
  }

  startPolling() {
    this.intervalId = setInterval(() => {
      this.checkStatus()
    }, this.intervalValue || 5000)
  }

  async checkStatus() {
    console.log("Checking status")
    try {
      const response = await fetch(`/crewai_plus/deployments/${this.deploymentIdValue}/check_provision_status.json`)
      console.log("Response", response)
      const data = await response.json()

      // Missing element
      this.statusTarget.textContent = data.status

      console.log("Updating")
      console.log(data)
      // this.updateSteps(data)

      if (data.status === "Crew is Online") {
        clearInterval(this.intervalId)
        console.log("Showing success state")
        this.showSuccessState()
      } else if (data.status.includes("Failed")) {
        clearInterval(this.intervalId)
        this.showFailureState()
      }
    } catch (error) {
      console.error("Error checking deployment status:", error)
    }
  }

  updateSteps(data) {
    const steps = [
      { name: "Starting", description: "Initializing deployment" },
      { name: "Building", description: "Building your crew" },
      { name: "Deploying", description: "Deploying your crew" },
      { name: "Online", description: "Your crew is ready!" }
    ]

    const currentStep = this.getCurrentStep(data.status)
    this.stepsTarget.innerHTML = steps.map((step, index) => this.stepTemplate(step, index, currentStep)).join("")
  }

  getCurrentStep(status) {
    if (status.includes("Failed")) return -1
    if (status === "Crew is Online") return 4
    if (status.includes("Deploying")) return 2
    if (status.includes("Building")) return 1
    return 0
  }

  showSuccessState() {
    // Add success celebration UI
    const successBanner = `
      <div class="mt-6 p-4 bg-green-50 rounded-lg border border-green-200">
        <div class="flex items-center">
          <svg class="w-5 h-5 text-green-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <h3 class="text-green-800 font-medium">Deployment Successful!</h3>
        </div>
        <p class="mt-2 text-sm text-green-700">Your crew is now online and ready to use.</p>
        <div class="mt-4">
          <a href="/crewai_plus/onboarding/use/${this.deploymentIdValue}" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
            Use Your Crew
          </a>
        </div>
      </div>
    `
    this.element.insertAdjacentHTML('beforeend', successBanner)
  }

  showFailureState() {
    // Add failure message UI
    const failureBanner = `
      <div class="mt-6 p-4 bg-red-50 rounded-lg border border-red-200">
        <div class="flex items-center">
          <svg class="w-5 h-5 text-red-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <h3 class="text-red-800 font-medium">Deployment Failed</h3>
        </div>
        <p class="mt-2 text-sm text-red-700">There was an error deploying your crew. Please try again or contact support.</p>
        <div class="mt-4 space-x-4">
          <a href="/crewai_plus/onboarding/complete" data-method="post" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
            Go the dashboard and complete the onboarding
          </a>
        </div>
      </div>
    `
    this.element.insertAdjacentHTML('beforeend', failureBanner)
  }

  stepTemplate(step, index, currentStep) {
    const isComplete = index < currentStep
    const isCurrent = index === currentStep
    const iconClass = isComplete ? "text-green-600" : isCurrent ? "text-blue-600" : "text-gray-400"

    return `
      <div class="flex items-center">
        <div class="flex-shrink-0">
          <span class="h-8 w-8 rounded-full flex items-center justify-center ${iconClass} bg-white border-2 border-current">
            ${isComplete ? this.checkIcon : isCurrent ? this.spinnerIcon : this.emptyIcon}
          </span>
        </div>
        <div class="ml-4">
          <p class="text-sm font-medium text-gray-900">${step.name}</p>
          <p class="text-sm text-gray-500">${step.description}</p>
        </div>
      </div>
    `
  }

  get checkIcon() {
    return `<svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
    </svg>`
  }

  get spinnerIcon() {
    return `<svg class="animate-spin w-5 h-5" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>`
  }

  get emptyIcon() {
    return `<span class="h-2.5 w-2.5 rounded-full bg-current"></span>`
  }
}
