import React from 'react';

const CrewTaskModal = ({ task, onClose }) => {
  return (
    <div className="task-modal-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center" onClick={onClose}>
      <div className="task-modal-content bg-white rounded-lg p-8 max-w-md w-full shadow-xl" onClick={(e) => e.stopPropagation()}>
        <h2 className="text-2xl font-bold m-0 mb-4">Task Details</h2>
        <div>
          <div className="mb-4">
            <span className="font-semibold text-sm block mb-2">Description:</span>
            <div className="text-sm bg-gray-50 p-3 rounded-lg whitespace-pre-wrap">
              {task.description}
            </div>
          </div>

          <div className="mb-4">
            <span className="font-semibold text-sm block mb-2">Expected Output:</span>
            <div className="text-sm bg-gray-50 p-3 rounded-lg whitespace-pre-wrap">
              {task.expected_output}
            </div>
          </div>

          <div className="mb-4">
            <span className="font-semibold text-sm block mb-2">Tools:</span>
            <div className="text-sm bg-gray-50 p-3 rounded-lg">
              {task.tools.length > 0 ? (
                <ul className="list-disc list-inside">
                  {task.tools.map((tool, index) => (
                    <li key={index}>{tool}</li>
                  ))}
                </ul>
              ) : (
                <span className="text-gray-500">No tools assigned</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrewTaskModal;
